import * as React from "react"
import Spacer from "../components/spacer/Spacer";
import Helmet from "react-helmet";
import Page from "../components/page/Page";

const PrivacyPolicyPage = () => {
  const spacer = <Spacer amount="20" />

  return (
    <Page>
      <Helmet>
        <title>Privacy Policy | Bravemark</title>
        <meta name="title" content="Terms of Use | Bravemark" />
        <meta property="og:title" content="Terms of Use" />
        <body className="no-bg" />
      </Helmet>

      <Spacer amount="48" hideOnNarrowDevices={true} />

      <h1>Terms of Use</h1>
      {spacer}

      <p>Read our terms below to learn more about your rights and responsibilities as a Bravemark user.</p>

      <p>This Terms of Service (the “Terms”) is entered into by and between Bravemark Inc. (“Bravemark “we”, “our” or “us”) and the entity or person placing an order for or accessing the Services (“Customer”) describes the rights and responsibilities that apply to the Customer's use of Bravemark’s websites, services, and mobile app (collectively, the “Service”).</p>

      <p>Please read the Terms carefully before using the Service. If you don’t agree to the Terms, as well as Bravemark’s Privacy Policy (the “Privacy Policy”) you may not use the Service. If you are entering into the Terms on behalf of a company or other legal entity, you represent that you have the authority to bind such entity to the Terms. If you do not have such authority, you must not accept the Terms or use the Service on behalf of such entity. The Service is only available to you if you have entered the age of majority in your jurisdiction of residence and are fully able and competent to enter into, abide by and comply with the Terms.</p>
      {spacer}

      <p><strong>1. Your Bravemark Account.</strong> If you create an account on the Service (your “Account”), you are responsible for maintaining the security of your Account and its (“Content”) meaning the code, content, fonts, graphics, designs, documents, or materials created using the Services by Customer and its Users or imported into the Services by Customer and its Users. , and you are fully responsible for all activities that occur under your Account and any other actions taken on the Service. You must not describe or assign Content to your Account in a misleading or unlawful manner, including in a manner intended to trade on the name or reputation of others, and Bravemark may change or remove any description or keyword that it considers inappropriate or unlawful, or otherwise likely to cause Bravemark liability. You must immediately notify Bravemark of any unauthorized uses of your Account or any other breaches of security. Bravemark will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions.</p>

      <p>For paid Accounts (each, a “Paid Account”), Bravemark deems the person under whose name appears on the credit or charge card or other payment method that pays the charges for the Service to be the owner of the Paid Account. If a corporate or other organization’s name appears on the credit or charge card or other payment method, that corporation or organization is deemed to be the owner. If any dispute arises as to who owns or controls a Paid Account, your most recently provided payment method will continue to be charged and you will be responsible to pay for the Service until Bravemark receives written confirmation from the Paid Account email address confirming a change in ownership and control of the Paid Account.</p>

      {spacer}
      <p><strong>2. Fees and Renewals.</strong> Pricing for Paid Accounts will be provided at the time of purchase. Unless otherwise set out at the time of purchase or as agreed to by Bravemark, fees for a Paid Account will be billed in advance for each year or on a monthly basis as chosen by you. There will be no refunds or credits for partial months of service or for periods in which your Paid Account remains open but you do not use the Service.</p>

      <p>We reserve the right to modify our billing rates at any time upon thirty (30) days written notice by posting such fee changes to the Service or through email notification to you. You will be liable to pay such modified billing rates.</p>

      <p>Subject to your payment of applicable fees for a Paid Account, we will provide the Service to you for the period of time that you have paid for such Service (the “Subscription Period”). At the end of the Subscription Period, your Paid Account subscription will automatically renew for an additional Subscription Period until it is expressly cancelled by you as described in the “Termination” section below.</p>

      <p>If you purchase any fee-based Service, you agree that our third party payment gateways, such as Stripe and Paypal, may store your credit or charge card or other payment information. You expressly agree that we are authorized to charge you (i) a fee for any applicable Service for which you have subscribed, billed on a basis of the Subscription Period, (ii) any other fees for the Service you may purchase, (iii) any charges for use of the Service in excess of the usage or other limits placed on your use of the Service (and you hereby consent to such charges and agree we are not required to notify you of any such charge in advance) and (iv) any applicable taxes in connection with your use of the Service to the credit or charge card you provide and to reimburse us for all collection costs and interest for any overdue amounts. If the credit or charge card you provide expires and you do not provide new credit or charge card information or cancel your Account, you authorize us to continue billing you and you agree to remain responsible for any uncollected fees.</p>

      {spacer}
      <p><strong>3. Your Responsibilities.</strong> If you operate an Account, publish a project, comment on a project, post material to the Service, post links on the Service, or otherwise make or allow any third party to make Content available by means of the Service, you are entirely responsible for the content of, and any harm resulting from, that Content. That is the case regardless of whether the Content in question constitutes texts, graphics or audio. By making Content available, you represent and warrant that:</p>

      <ul>

      <li>The Content displayed in the Services does not and will not violate any law or infringe any rights of any third party, including without limitation any Intellectual Property Rights, patent, trademark or trade secret rights, publicity rights, or rights of privacy;</li>

      <li>If your employer has rights to intellectual property you create, you have either (i) received permission from your employer to post or make available the Content, including but not limited to any software, or (ii) secured from your employer a waiver as to all rights in or to the Content;</li>

      <li>You have fully complied with any third-party licenses relating to the Content, and have done all things necessary to successfully pass through to end users any required terms;</li>

      <li>The Content does not contain or install any viruses or other harmful or destructive content;</li>

      <li>The Content is not spam, is not machine- or randomly-generated, and does not contain unethical or unwanted commercial content designed to drive traffic to third party sites or boost the search engine rankings of third party sites, or to further unlawful acts (such as phishing) or mislead recipients as to the source of the material (such as spoofing);</li>

      <li>The Content is not obscene, libelous or defamatory, hateful or racially or ethnically objectionable, and does not violate the privacy or publicity rights of any third party;</li>

      <li>Your Account is not getting advertised via unwanted electronic messages such as spam links on newsgroups, email lists, other blogs and web sites, and similar unsolicited promotional methods;</li>

      <li>Your Account is not named in a manner that misleads your readers into thinking that you are another person or company. For example, your Account’s URL or name is not the name of a person other than yourself or company other than your own;</li> 

      </ul>

      <p>You have, in the case of Content that includes computer code, accurately categorized and/or described the type, nature, uses and effects of the materials, whether requested to do so by Bravemark or otherwise.</p>

      <p>By uploading Content or otherwise providing Content to Bravemark, you grant Bravemark the right to use (including the right to host, index, cache and format) and display your Content in connection with providing the Service as well as otherwise on the Site and our marketing communications with you and other users and prospective users of the Service, including without limitation on our emails, marketing channels and other promotional materials (subject to the requirements set out in the Privacy Policy). To the extent we use your Content in our marketing communications, unless you expressly permit us to do otherwise, we will always cite you as the owner of such Content. This paragraph will survive the termination or expiry of the Terms.</p>

      <p>Bravemark reserves the right to refuse or remove any Content or terminate or deny access to your use of the Service for any reason whatsoever. Please read the Community Guidelines for rules and advice on what types of Content and uses of the Service are appropriate at Bravemark.</p>

      <p>All Content uploaded are copyright © of their respective owners.</p>

      <p>If you delete Content, Bravemark will use reasonable efforts to remove it from the Service, but you acknowledge that caching or references to the Content may not be made immediately unavailable.</p>  

      
      {spacer}
      <p><strong>4. Rules.</strong> Use of any of the Services is subject to the following rules. You agree that you will not do any of the following:</p>
      
      <ul>
 
      <li> Use, support or develop bots, scrapers, software, scripts, crawlers, browser plugins, robots or other automated methods including human-powered automation through like farming or any other crowd-sourced methods to access, scrape, or copy the Services, or to add or download data, or send or redirect information or messages;</li>

      <li> Reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for the Services or any related technology that we have not made open source;</li>

      <li> Rent, lease, loan, trade, sell or otherwise monetize the Services or related data or access to the same, without our express written consent;</li>

      <li> Monitor the Services’ availability, performance or functionality for any competitive purpose;</li>

      <li> Engage in “framing,” “mirroring,” or otherwise simulating the appearance or function of the Services;</li>
      
      <li>Post anything that contains software viruses, worms, or any other harmful code;</li>

      <li> Overlay or otherwise modify the Services or their appearance (such as by inserting elements into the Services or removing, covering, or obscuring an advertisement included on the Services);</li>

      <li> Interfere with the operation of, or place an unreasonable load on, the Services (e.g., spam, denial of service attack, viruses, gaming algorithms);</li>

      <li> Override any security feature or bypass or circumvent any access controls or limits of the Services;</li>

      <li> Create a false identity, misrepresent your identity, create a profile for anyone other than yourself, or use or attempt to use another’s account; or</li>

      <li> Violate our intellectual property rights or the intellectual property rights of others.</li>

      </ul>

      {spacer}
      <p><strong>5. Third Party Websites and Content.</strong> We have not reviewed, and cannot review, all of the material, including computer software, made available through the websites and webpages to which Bravemark links, and that link to Bravemark. Bravemark does not have any control over those non-Bravemark websites and webpages, and is not responsible for their contents or their use. By linking to a non-Bravemark website or webpage, Bravemark does not represent or imply that it endorses such website or webpage. You are responsible for taking precautions as necessary to protect yourself and your computer systems from viruses and other harmful or destructive content. Bravemark disclaims any responsibility for any harm resulting from your use of non-Bravemark websites and webpages.</p>

      {spacer}
      <p><strong>6. Intellectual Property.</strong> All material available on the Service and all material and services provided by or through Bravemark, its affiliates, subsidiaries, employees, agents, licensors or other commercial partners including, but not limited to, software, all informational text, software documentation, design of and “look and feel”, layout, photographs, graphics, audio, video, messages, interactive and instant messaging, design and functions, files, documents, images, or other materials, whether publicly posted or privately transmitted as well as all derivative works thereof (collectively, the “Materials”), are owned by us or our licensors or service providers, and are protected by copyright, trademark, trade secret and other intellectual property laws.</p>

      <p>If Bravemark, in its sole discretion and without notice, considers that there is an immediate security or operational risk to the Service, any of its, your or a third party system, then Bravemark may immediately suspend access to or use of the Service. The suspension of use and access is not a breach of the Terms. You acknowledge that the preservation of security, confidentiality and data is paramount. Bravemark has no liability to you for suspending the Service under this provision.</p>

      <p>This section does not apply to Content; however you agree that any ideas, suggestions, concepts, processes or techniques which you provide to Bravemark related to the Service or Bravemark or its business (“Feedback”) are and will be Bravemark’s exclusive property without any compensation or other consideration payable to you by Bravemark, and you do so of your own free will and volition. Bravemark may or may not, in its sole discretion, use or incorporate the Feedback in whatever form or derivative Bravemark may decide into the Service or its business or other products. You hereby assign all rights on a worldwide basis in perpetuity to Bravemark in any Feedback and, as applicable, waive any moral rights.</p>

      <p>For the purposes of the Terms and the Privacy Policy, “personal information” is any information about an identifiable individual, as defined in our Privacy Policy.</p>

      <p>Bravemark retains the right to use or share any Aggregated Data generated by anyone using the Service, including our users, for the purpose of enhancing and providing the Service. “Aggregated Data” means data does not contain personal information and which has been manipulated or combined to provide generalized, anonymous information. Where you choose to utilize or connect certain services from third parties with the Service, you agree that Bravemark may share your lead data with such designated third parties. You are still responsible for any and all personal information that is part of any Content.</p>

      {spacer}
      <p><strong>7. Changes.</strong> Bravemark reserves the right, at its sole discretion, to modify or replace any part of the Terms. It is your responsibility to check the Terms periodically for changes. Your continued use of or access to the Service following the posting of any changes to the Terms constitutes acceptance of those changes. Bravemark may also, in the future, offer new services and/or features through the Service (including, the release of new tools and resources). Such new features and/or services shall be subject to the Terms.</p>

      {spacer}
      <p><strong>8. Termination.</strong> Bravemark may terminate your access to all or any part of the Service at any time, with or without cause, with or without notice, effective immediately. If you wish to terminate the Terms or your Account (if you have one), you may simply discontinue using the Service. All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>

      {spacer}
      <p><strong>9. Disclaimer of Warranties.</strong> Your use of the Service and all Content forming part of or related to the Service, including any Content you upload or submit and any third party software and Content, are at your sole responsibility and risk. The Service are provided on an “as is” and “as available” basis. Bravemark expressly disclaims all representations, warranties, or conditions of any kind with respect to the Service, whether express or implied, including, but not limited to, any implied warranties or conditions of merchantability, fitness for a particular purpose, accuracy, completeness, performance, system integration, quiet enjoyment, title, and non-infringement.</p>

      <p>The service is offered and controlled by Bravemark from its office in Spain. Bravemark makes no representations that the service is appropriate or available for use in locations other than Spain. Those who access or use the Service from other jurisdictions do so at their own volition and are responsible for compliance with local law. We handle complaints of copyright infringement in accordance with our Copyright Policy.</p>

      {spacer}
      <p><strong>10. Limitation of Liability.</strong> You agree that, under no legal theory, including, but not limited to negligence, breach of warranty or condition, breach of contract or tort, will Bravemark or its owners, officers, directors, affiliates, contractors, employees or agents, be liable to you or any third party acting on your behalf for any indirect, incidental, special, consequential, punitive, or exemplary damages or damages for loss of profits, goodwill, use, data, or other intangible losses or the cost of any substitute equipment, facilities or services (even if Bravemark has been advised of the possibility of such damages), arising from or relating to the Terms or your use of or your inability to use the Service, or for any damages arising from or related to the Terms. Bravemark’s total aggregate liability from any and all claims under the Terms is limited to the total amounts you paid to Bravemark in the one (1) year immediately preceding the occurrence of loss or damage. To the extent any province, state or jurisdiction does not allow the exclusion or the limitation of liability for consequential or incidental damages, Bravemark’s liability in such province, state or jurisdiction will be limited to the furthest extent permitted by law. Notwithstanding the foregoing or anything else herein to the contrary, Bravemark will not be liable to you or any third party acting on your behalf in any way with respect to a trial program or your provision of an individual’s personal information to Bravemark or through the Service. You further agree that the foregoing limitations will apply with respect to third party liability of any kind.</p>

      <p>The foregoing limitations will also apply with respect to any damages incurred by reason of any content or services provided on any third party sites or otherwise provided by any third parties other than Bravemark and received by you through or advertised on the Service or received by you on any third party sites. You also agree that Bravemark will not be responsible or liable for any loss or damage of any kind incurred as the result of any interactions or dealings with advertisers or as the result of the presence of such advertisers on the Service.</p>

      <p>With respect to any dispute arising out of or related to the Service and/or the Terms: (i) you hereby expressly give up your right to have a trial by jury; and (ii) you hereby expressly give up your right to participate as a member of a class of claimants, in any lawsuit including but not limited to class action lawsuits involving any such dispute.</p>

      <p>You agree that you will not bring a claim under or related to the Terms more than one (1) year from when your claim first arose.</p>

      {spacer}
      <p><strong>11. General Representation and Warranty.</strong> You represent and warrant that (i) your use of the Service will be in strict accordance with the Terms, the Privacy Policy, and all applicable laws and regulations (including without limitation any local laws or regulations in your country, state, city, or other governmental area, regarding online conduct and acceptable content, and including all applicable laws regarding the transmission of technical data exported from the Canada or the country in which you reside) and (ii) your use of the Service will not infringe or misappropriate the intellectual property rights of any third party.</p>

      {spacer}
      <p><strong>12. Indemnification.</strong> You agree to indemnify, defend, and hold harmless Bravemark, and its subsidiaries, affiliates, co-branders, all third-party advertisers, technology providers, service providers or other partners, and each of their respective officers, directors, agents, shareholders, employees and representatives (together, the “Indemnified Parties”), from and against any third party claim, demand, loss, damage, cost, or liability (including, reasonable attorneys’ fees) (collectively and individually, “Claims”) incurred by or made against the Indemnified Parties in connection with any Claims arising out of or relating to the Terms, the Service, including but without limitation in relation to: (a) your use, non-use or misuse of, or connection to the Service and any Content; (b) your violation or alleged violation of the Terms; and (c) your violation of any rights, including intellectual property rights, of a third party and otherwise as set out herein. Bravemark reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify Bravemark and you agree to cooperate with Bravemark’s defense of these Claims. You agree not to settle any matter without the prior written consent of Bravemark. Bravemark will use reasonable efforts to notify you of any such Claims upon becoming aware of it.</p>


      {spacer}
      <p><strong>15. Contacting Bravemark.</strong> If you have any questions or comments about this Privacy Policy or your personal information, to make an access or correction request, to exercise any applicable rights, to make a complaint, or to obtain information about our policies and practices please contact us by email at support@bravemark.co</p>

    </Page>
  )
}

export default PrivacyPolicyPage
